/**
 * # SkillData
 * @description
 * Skills (사용가능 기술) 항목에 사용되는 데이터 인터페이스 입니다.
 */
interface SkillData {
  /**
   * # title
   * @remarks 기술의 이름
   * @example 'JavaScript'
   */
  title: string
  /**
   * # level
   * @remarks 기술의 수준
   * @example 'Major'
   */
  level: 'Major' | 'Minor'
  /**
   * # descriptions
   * @remarks 기술의 수준 설명들
   * @example ```ts
   * descriptions: [
   *   'ES6 문법을 활용해 웹 어플리케이션을 개발하는 데 익숙합니다.',
   *   'Vanilla JavaScript를 활용해 DOM 조작하는 데 익숙합니다.',
   *   'reduce, map, filter 등 다양한 고차함수를 적극적으로 활용합니다.'
   * ]
   * ```
   */
  descriptions: string[]
}

/**
 * # AwardAndProjectData
 * @description
 * Award and Project (수상 및 프로젝트 경험) 항목에 사용되는 데이터 인터페이스
 */
interface AwardAndProjectData {
  /**
   * # title
   * @remarks 수상 혹은 프로젝트 명
   * @example 'KB AppChallenge 대상'
   */
  title: string
  /**
   * # date
   * @remarks 수상일 혹은 프로젝트 진행 기간
   * @example '2018.11'
   */
  date: string
  /**
   * # descriptions
   * @remarks 해당 수상 혹은 프로젝트에 대한 설명들
   * @example ```ts
   * descriptions: [
   *   'KB 금융지주에서 SOPT 회원들을 대상으로 약 2주 동안 진행된 해커톤입니다.',
   *   'OpenCV와 Project Oxford를 활용하여 영수증 인식 기능을 구현을 담당했습니다.',
   *   '간단하게나마 앱 내에서 영상처리 기술을 시도해본 좋은 경험이었습니다.'
   * ]
   * ```
   */
  descriptions: string[]
}

/**
 * # HobbyData
 * @description
 * Hobby (취미) 항목에 사용되는 데이터 인터페이스
 */
interface HobbyData {
  /**
   * # title
   * @remarks 취미 이름
   * @example '낚시'
   */
  title: string
  /**
   * # descriptions
   * @remarks 취미에 대한 설명들 (어떻게 즐기는지 등)
   * @example ```ts
   * descriptions: [
   *   '여건상, 주로 실내 낚시 카페에 갑니다.',
   *   '생각을 정리하고 머리를 식힐겸 새벽 및 퇴근 후에도 자주 가고 있습니다.'
   * ]
   * ```
   */
  descriptions: string[]
}

/**
 * # EducationData
 * @description
 * Education 학력사항
 */
interface EducationData {
  /**
   * # title
   * @remarks 교육 이름
   * @example '학교'
   */
  title: string
  /**
   * # descriptions
   * @remarks 취미에 대한 설명들 (어떻게 즐기는지 등)
   * @example ```ts
   * descriptions: [
   *   '여건상, 주로 실내 낚시 카페에 갑니다.',
   *   '생각을 정리하고 머리를 식힐겸 새벽 및 퇴근 후에도 자주 가고 있습니다.'
   * ]
   * ```
   */
  date: string
  /**
   * # descriptions
   * @remarks 해당 수상 혹은 프로젝트에 대한 설명들
   * @example ```ts
   * descriptions: [
   *   'KB 금융지주에서 SOPT 회원들을 대상으로 약 2주 동안 진행된 해커톤입니다.',
   *   'OpenCV와 Project Oxford를 활용하여 영수증 인식 기능을 구현을 담당했습니다.',
   *   '간단하게나마 앱 내에서 영상처리 기술을 시도해본 좋은 경험이었습니다.'
   * ]
   * ```
   */
  descriptions?: string[]
}

interface EnglishData {
  /**
   * # title
   * @remarks 교육 이름
   * @example '학교'
   */
  title: string
  /**
   * # descriptions
   * @remarks 취미에 대한 설명들 (어떻게 즐기는지 등)
   * @example ```ts
   * descriptions: [
   *   '여건상, 주로 실내 낚시 카페에 갑니다.',
   *   '생각을 정리하고 머리를 식힐겸 새벽 및 퇴근 후에도 자주 가고 있습니다.'
   * ]
   * ```
   */
  date: string
  /**
   * # descriptions
   * @remarks 해당 수상 혹은 프로젝트에 대한 설명들
   * @example ```ts
   * descriptions: [
   *   'KB 금융지주에서 SOPT 회원들을 대상으로 약 2주 동안 진행된 해커톤입니다.',
   *   'OpenCV와 Project Oxford를 활용하여 영수증 인식 기능을 구현을 담당했습니다.',
   *   '간단하게나마 앱 내에서 영상처리 기술을 시도해본 좋은 경험이었습니다.'
   * ]
   * ```
   */
  descriptions?: string[]
}

/**
 * # WorkExperienceData
 * @description
 * Work Experience (경력) 항목에 사용되는 데이터 인터페이스
 */
interface WorkExperienceData {
  /**
   * # company
   * @remarks 회사명
   * @example 'HIGHERX'
   */
  company: string
  /**
   * # date
   * @remarks 재직 기간
   * @example '2019.01 ~ 현재 재직중'
   */
  date: string
  /**
   * # position
   * @remarks 직군
   * @example 'Software Engineer'
   */
  position: string
  /**
   * # team
   * @remarks 소속된 팀
   * @example 'Platform DEV'
   */
  team: string
  /**
   * # description
   * @remarks 회사에 대한 간단한 설명
   * @example '매장 업무관리 서비스 워키도키(전. 알바체크) 서비스 개발\n\n무인 매장에 필요한 시간에 필요한 인력을 배치하는 브라우니 서비스 개발'
   */
  description: string
  /**
   * # experiences
   * @remarks 경력 내용들
   * @example ```ts
   * [
   *   {
   *     title: '브라우니 결제 서버 개발 및 유지보수',
   *     date: '2021.05 ~',
   *     description: '브라우니 서비스에 사용되는 결제 서버 구현 및 유지보수를 담당하고 있습니다.\n기술 스택: koajs, TypeScript',
   *     summary: [
   *       'PG사 (KG 이니시스)를 통한 결제 구현',
   *       '카드 결제, 계좌이체, 무통장입금 구현',
   *       '환불 시스템 구현',
   *       '포인트, 쿠폰 시스템 구성'
   *     ]
   *   }
   * ]
   * ```
   */
  experiences: {
    /**
     * # title
     * @remarks 프로젝트 제목
     * @example '브라우니 결제 서버 개발 및 유지보수'
     */
    title: string
    /**
     * # date
     * @remarks 프로젝트 진행 기간
     * @example '2021.05 ~'
     */
    date: string
    /**
     * # description
     * @remarks 프로젝트 설명
     * @example '브라우니 서비스에 사용되는 결제 서버 구현 및 유지보수를 담당하고 있습니다.\n기술 스택: koajs, TypeScript'
     */
    description: string
    /**
     * # summary
     * @remarks 프로젝트 요약
     * @example ```ts
     * [
     *   'PG사 (KG 이니시스)를 통한 결제 구현',
     *   '카드 결제, 계좌이체, 무통장입금 구현',
     *   '환불 시스템 구현',
     *   '포인트, 쿠폰 시스템 구성'
     * ]
     * ```
     */
    summary: string[]
  }[]
}

/**
 * # Introduce
 * @description
 * Introduce (자기소개, 제일 상단 항목) 항목에 사용되는 데이터 인터페이스
 */
interface Introduce {
  /**
   * # name
   * @remarks 이름
   * @example '정윤재'
   */
  name: string
  /**
   * # position
   * @remarks 직군
   * @example 'Software Engineer'
   */
  position: string
  /**
   * # contactMethods
   * @remarks 연락 수단
   * @example ```ts
   * {
   *   text: 'GitHub',
   *   link: 'https://github.com/KimChunsick'
   * }
   * ```
   */
  contactMethods: { text: string; link: string }[]
  /**
   * # selfIntroduce
   * @remarks 자기소개 내용
   * @example '안녕하세요. 소프트웨어 엔지니어 정윤재입니다.'
   */
  selfIntroduce: string
}

/**
 * # ResumeData
 * @description
 * 이력서 데이터 인터페이스
 *
 * 다른 곳에서 사용할 수 있으니 originate하게 따로 사용하기 위해 optional 값 없이 선언
 */
interface ResumeData {
  introduce: Introduce
  skill: SkillData[]
  awardAndProject: AwardAndProjectData[]
  hobby: HobbyData[]
  workExperience: WorkExperienceData[]
  education: EducationData[]
  english: EnglishData[]
}

/**
 * # NeedResumeData
 * @description
 * 실질적으로 이력서에 사용되는 타입
 *
 * 자유롭게 필수값, 선택사항 값 선택하여 만든 후 아래 data의 타입으로 지정하면 됨.
 */
type NeedResumeData = Pick<ResumeData, 'introduce'> &
  Partial<Pick<ResumeData, 'awardAndProject' | 'hobby' | 'skill' | 'workExperience' | 'education' | 'english'>>

// 이 객체에 이력서 데이터 추가하면 됨
const data: NeedResumeData = {
  introduce: {
    name: '정윤재',
    position: 'Software Engineer',
    selfIntroduce:
      '안녕하세요. 소프트웨어 엔지니어 정윤재입니다.\n소프트웨어를 개발하면서 다양한 문제를 마주하고 해결하는 것을 좋아합니다. 2년간 서비스를 운영/개발하면서, 점진적으로 발전하는 서비스를 보며 뿌듯함을 느꼈습니다.\n출시일부터 현재까지 리텐션 90% 이상, 누적 이용 횟수 1만 건을 돌파한 브라우니 무인 매장 서비스의 초기 멤버로 함께하였습니다. 서비스 성장 과정에서 1년간 총 3차례에 걸친 홈페이지 리뉴얼 프로젝트에 참여했으며, 이를 통해 이전보다 더 개선된 사용자 경험을 맞이했을 때 가장 큰 만족감을 느꼈습니다.',
    contactMethods: [
      { text: 'yunjae_cont@naver.com', link: 'mailto:yunjae_cont@naver.com' },
      { text: 'GitHub', link: 'https://github.com/jeongyunjae' }
      // { text: 'Blog', link: 'https://yunjaaae.com' }
    ]
  },
  skill: [
    {
      title: 'JavaScript',
      level: 'Major',
      descriptions: [
        'ES6 문법을 활용해 웹 어플리케이션을 개발하는 데 익숙합니다.',
        'Vanilla JavaScript를 활용해 DOM 조작하는 데 익숙합니다.',
        'map, reduce, filter 등 다양한 고차함수를 적극적으로 활용합니다.'
      ]
    },
    {
      title: 'TypeScript',
      level: 'Minor',
      descriptions: [
        'TypeScript를 활용해 웹 어플리케이션을 제작하는 데 익숙합니다.',
        '때에 따라 interface와 type을 적절히 나누어 사용할 수 있습니다.',
        'Utility Type들을 활용해 약간 복잡한 Type들을 생성하여 개발할 수 있습니다.'
      ]
    },
    {
      title: 'HTML / CSS',
      level: 'Minor',
      descriptions: [
        '디자인을 보고 페이지의 구조를 결정할 수 있습니다.',
        'Chrome, Safari, InApp 등 다양한 브라우저 환경을 지원해 본 경험이 있습니다.',
        '스마트폰 OS별 반응형 웹 적용 경험이 있습니다.',
        'Search Engine Optimization 적용 경험이 있습니다.'
      ]
    },
    {
      title: 'Server',
      level: 'Minor',
      descriptions: [
        'koajs를 활용해 RESTful API를 작성할 수 있습니다.',
        '서비스에서 한 도메인을 맡아 주도적으로 DB 및 API 설계를 경험해 보았습니다.',
        'JWT 인증을 사용하여 클라이언트와 서버 간 인증 유지를 적용해 본 경험이 있습니다.',
        '팀의 생산성을 위해 Slack Bot 개발 및 서버와 연동한 경험이 있습니다.'
      ]
    }
  ],
  awardAndProject: [
    {
      title: '숭실 캡스톤디자인 경진대회 특허출원 및 수상',
      date: '2023.03 ~ 2023.09',
      descriptions: [
        '특허명: 음악 추천 시스템 및 음악 추천 시스템의 제어 방법 (출원번호: 10-2023-0101887)',
        '일기 속 감성분석 기반 음악 추천 프로젝트로부터 출원된 특허입니다.',
        '어플리케이션 디자인과 UI 개발을 담당했습니다.',
        '특허출원 과정을 통해 명확하고 일관성 있는 보고서 작성법을 배울 수 있었으며, 개발한 사항을 글로 풀어내는 능력을 길러볼 수 있었던 값진 경험이었습니다.'
      ]
    },
    {
      title: '소프트웨어학부 창의적 공학설계 장려상',
      date: '2018.09 ~ 2018.12',
      descriptions: [
        '아두이노 기반 피아노 LED분수 제작 프로젝트를 진행했습니다.',
        '분수대 용접 및 아두이노 동작 코드 작성을 담당했습니다.',
        '프로젝트가 하나가 완료되는 것이 얼마나 힘든 것인지 몸소 깨닫게 해준 정말 감사한 프로젝트였습니다.'
      ]
    }
  ],
  hobby: [
    {
      title: '오케스트라 활동',
      descriptions: [
        '숭실대학교 관현악 동아리 아반도네즈의 단원으로 바이올린을 연주했습니다.',
        '단원들과 함께 1년에 한 번뿐인 정기연주회를 위해, 치열하고 열심히 준비했던 과정이 즐거웠습니다.\n\n'
      ]
    },
    {
      title: '독서',
      descriptions: [
        '주로 고전철학과 인문학 서적을 위주로 읽습니다.',
        '보통 한 달에 2권 정도씩 틈틈이 읽고 있습니다.',
        '책의 내용을 실생활에 연관시켜 보거나, 새로운 관점과 시각을 책에서 발견할 때에 가장 기분이 좋습니다.'
      ]
    },
    {
      title: '운동',
      descriptions: ['운동의 필요성을 느끼기 시작한 올해 초부터, 헬스를 주 3회 정도 꾸준히 하고 있습니다.']
    }
  ],
  workExperience: [
    {
      company: 'HIGHERX',
      date: '2020.12 ~ 2023.01',
      position: 'Software Engineer',
      team: 'Platform DEV',
      description:
        '무인매장 정기 관리 서비스 브라우니의 \n홈페이지 및 어드민웹 개발\n\n오프라인 맞춤형 매장관리 서비스 워키도키의 어드민웹 개발',
      experiences: [
        {
          title: '브라우니 MSA기반 인증 서버 개발 및 유지보수',
          date: '2021.06 ~ 2023.01',
          description:
            '브라우니 서비스에 사용되는 인증 서버 구현 및 유지보수를 담당했습니다.\n\n기술 스택: AWS, Redis, Node.js(Koa), TypeScript, passportJs',
          summary: [
            'AWS CodePipeline를 활용한 CI/CD 프로세스 구성과 EC2 생성 및 관리',
            'JWT 인증 방식을 통한 토큰 관리 및 로그인 유지 구현',
            'PASS 인증, 휴대전화 인증, 이메일 인증 구현',
            'passport.js를 통한 소셜 로그인 제공'
          ]
        },
        {
          title: '브라우니 서비스 홈페이지 리뉴얼 개발 (총 3차)',
          date: '2022.04 ~ 2023.02',
          description:
            '서비스 아하모먼트를 예비 고객에게 더 잘 전달할 수 있도록 UI/UX를 점진적으로 개선하며 개발했습니다.\n\n기술 스택: React, JavaScript, TypeScript',
          summary: [
            'Search Engine Optimization 제공',
            '사내 운영팀 메신저를 연동한 간편 상담 기능 구현',
            '화면크기와 OS별 반응형 디자인 적용\n\n'
          ]
        },
        {
          title: '브라우니 관리자 홈페이지 개발 및 유지보수',
          date: '2022.08 ~ 2023.01',
          description:
            '운영 파트 팀원들이 쉽고 편리하게 사용할 수 있도록 개발한 관리자 홈페이지입니다.\n결제, 상세 정보 등 서비스 이용매장의 CRUD를 지원하고 브라우니 크루(아르바이트생)의 급여 및 출근 일정 정보를 손쉽게 관리할 수 있도록 설계했습니다.\n\n기술 스택: React, Javascript',
          summary: ['문자메시지, 카카오톡 알림톡 전송 기능 구현', '기능 또는 페이지별 접근 권한 세분화 구현']
        },
        {
          title: '우리동네 무인매장 지도 서비스 개발',
          date: '2021.12 ~ 2022.02',
          description:
            '수도권에 포진된 무인매장의 위치, 정보 등을 지도를 통해 손쉽게 확인할 수 있도록 개발했습니다.\n지역별 무인매장 포진 상태와 업종 등의 정보를 필터를 조절하며 볼 수 있으며, 주로 무인매장 예비 창업주와 무인매장에 호기심 있는 일반 사용자층을 타깃 했습니다. \n\n서비스 도입 후, 지역별 광고 소재로 사용되기도 했으며 4개월간 홈페이지 MAU 평균 19% 증가에 기여했습니다.\n\n기술 스택: React, TypeScript, NaverMapsAPI, Express',
          summary: [
            '네이버 지도 API 연동 및 커스터마이징 구현',
            '줌 레벨에 따른 인접 항목 클러스터로 렌더링 개선',
            '지도 내 매장 및 위치 검색과 필터링 기능 구현'
          ]
        },
        {
          title: '워키도키 관리자웹 대규모 리팩토링 및 DX 개선',
          date: '2021.04 ~ 2021.05',
          description:
            '신기능을 더 일관성 있고 빠르게 개발할 수 있도록 하는 리팩토링에 참여했습니다.\n프로젝트 구조를 알기 쉬운 형태로 개선했으며, 복잡하고 개발 시간이 오래 걸리던 기존 API 통신 과정을 걷어내고 새롭게 적용하였습니다. 또한 부분 클라이언트 캐싱을 활용하여 웹 새로고침 빈도를 개선했습니다.  \n\n기술 스택: React, typescript, react-query',
          summary: [
            'API 통신 과정에서의 Redux와 Redux-Saga를 React-Query로 일괄 변경 및 적용',
            'Container 구조에서 Hook 방식으로 변경하며 비즈니스 로직과 레이아웃을 분리',
            '클라이언트 캐싱을 통한 Optimistic update 제공'
          ]
        }
      ]
    }
  ],
  education: [
    {
      title: '숭실대학교 소프트웨어학부 전공 (학사)',
      date: '2018.03 ~ 2024.08 (졸업예정)'
      // descriptions: ['학점: 3.16 / 4.5']
    }
  ],
  english: [
    {
      title: '영어: TOEIC SPEAKING IH (150)',
      date: '2023.12.30~'
    }
  ]
}

export default data
